<template>
    <div class="max-w-2xl mx-auto px-5 lg:px-0">
        <div class="min-h-screen flex flex-col divide-y divide-gray-700">
            <div class="w-full pt-10 lg:pt-15 pb-10 space-y-10 lg:space-y-15">
                <div class="relative">
                    <div
                        class="absolute inset-0 bg-gradient-to-r from-emerald-400 to-emerald-800 shadow-lg transform -rotate-6 rounded-3xl"
                    ></div>

                    <div class="space-y-5 p-7 lg:p-8 relative bg-gray-800 shadow-lg rounded-3xl">
                        <Hero />
                    </div>
                </div>

                <Links />
            </div>

            <div class="w-full space-y-5 py-10">
                <h3 class="text-sm font-medium uppercase tracking-widest text-emerald-400">
                    Projects
                </h3>

                <div class="grid lg:grid-cols-2 gap-5">
                    <Project v-for="(project, index) in projects" :key="index" :project="project" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import projects from '@/projects'
    import Hero from '@/components/Hero'
    import Links from '@/components/Links'
    import Project from '@/components/Project'

    export default {
        data() {
            return {
                projects: projects
            }
        },

        components: {
            Hero,
            Links,
            Project
        }
    }
</script>
