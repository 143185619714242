<template>
    <a
        :href="project.href"
        class="p-4 bg-gray-800 space-y-4 rounded-lg lg:transform lg:transition-transform lg:ease-in-out lg:duration-150 lg:hover:scale-105 focus:outline-none focus:shadow-outline-emerald lg:focus:scale-105"
    >
        <div class="space-y-2">
            <h1 class="text-lg font-semibold text-white">
                {{ project.name }}
            </h1>

            <p class="text-gray-400">
                {{ project.description }}
            </p>
        </div>
    </a>
</template>

<script>
    export default {
        props: {
            project: {
                required: true,
                type: Object
            }
        }
    }
</script>
