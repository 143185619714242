<template>
    <h1 class="text-3xl font-header font-semibold leading-tight text-white">
        Hi, I'm <span class="block text-4xl">Abdul Haseeb.</span>
    </h1>

    <p class="text-lg text-gray-400">
        I am an aspiring full stack developer from Toronto, Canada. I specialize in building web
        applications using the
        <span class="font-medium text-emerald-400">Laravel</span>,
        <span class="font-medium text-emerald-400">Vue</span>, and
        <span class="font-medium text-emerald-400">TailwindCSS</span> stack, but I am also starting
        to dabble with <span class="font-medium text-emerald-400">Flask</span> and
        <span class="font-medium text-emerald-400">React</span>.
    </p>

    <p class="text-lg text-gray-400">
        I am currently looking for internship oppurtunities.
    </p>
</template>
