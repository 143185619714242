let projects = [
    {
        name: 'Pickbox',
        description:
            'Built a file sharing platform where users can create folders, upload files, and get instant search results.',
        stack: ['Laravel', 'TailwindCSS'],
        href: 'https://github.com/ahaseeb04/pickbox'
    },
    {
        name: 'Chronos',
        description:
            'Built a fully tested task scheduler from scratch, and used cron expressions to determine if tasks should run.',
        stack: ['PHP'],
        href: 'https://github.com/ahaseeb04/chronos'
    },
    {
        name: 'Paginator',
        description: 'Devised a flexible algorithm from scratch in order to build page links.',
        stack: ['PHP'],
        href: 'https://github.com/ahaseeb04/paginator'
    }
]

export default projects
