<template>
    <div class="flex items-center space-x-4 lg:space-x-5">
        <a
            v-for="(link, index) in links"
            :key="index"
            :href="link.href"
            class="flex items-center space-x-2 text-white transition-colors ease-in-out duration-150 hover:text-emerald-400 focus:outline-none focus:text-emerald-400"
        >
            <span>{{ link.text }}</span>

            <svg
                class="w-4 h-4 flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
            </svg>
        </a>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                links: [
                    {
                        text: 'ahaseeb408@gmail.com',
                        href: 'mailto:ahaseeb408@gmail.com'
                    },
                    {
                        text: 'GitHub',
                        href: 'https://github.com/ahaseeb04'
                    }
                ]
            }
        }
    }
</script>
